import Footer from "components/Footers/Footer";
import Header from "components/Headers/Header";
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../assets/css/Success.css';

function Success() {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          navigate('/my-package');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div className="success-wrapper">
      <Header />
      <main className="success-main">
        <motion.div
          className="success-container"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="success-card">
            {/* Success Check Animation */}
            <motion.div
              className="check-container"
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="check-circle">
                <motion.svg
                  className="check-icon"
                  viewBox="0 0 24 24"
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ duration: 0.8, ease: "easeInOut" }}
                >
                  <motion.path
                    fill="none"
                    strokeWidth="3"
                    stroke="currentColor"
                    d="M20 6L9 17l-5-5"
                  />
                </motion.svg>
              </div>
            </motion.div>

            {/* Success Message */}
            <motion.div
              className="message-section"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <h1>Payment Successful!</h1>
              <p>Thank you for trusting our service</p>
            </motion.div>

            {/* Timer Section */}
            <motion.div
              className="timer-section"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <div className="timer-text">
                Redirecting to package page in
              </div>
              <motion.div
                className="timer-number"
                key={timeLeft}
                initial={{ scale: 1.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                {timeLeft}
              </motion.div>
            </motion.div>

            {/* Button */}
            <motion.button
              className="redirect-button"
              onClick={() => navigate('/my-package')}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
            >
              Go to Package Page
            </motion.button>
          </div>
        </motion.div>
      </main>
      <Footer />
    </div>
  );
}

export default Success;
