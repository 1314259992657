import Axios from 'axios';
import { useEffect, useState } from 'react';

import Footer from "components/Footers/Footer";
import Header from "components/Headers/Header";
import Payment from 'components/Payment/Payment';
import PurchaseRow from 'components/Payment/PurchaseRow';
import { createAlert, getDataByUserId } from 'utils/MainUtils';
import '../../../assets/css/package.css';

function Package() {
  const [packageList, setPackageList] = useState([]);
  const [resultMember, setResultMember] = useState([]);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [waitingApprove, setWaitingApprove] = useState(false);
  const [hiddenSubscribe, setHiddenSubscribe] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showPurchaseHistoryModal, setShowPurchaseHistoryModal] = useState(false);
  const [purchaseId, setPurchaseId] = useState('');
  const [purchaseHistory, setPurchaseHistory] = useState([]);

  useEffect(() => {
    initialize();
    checkPackage();
    checkMember();
  }, []);

  const initialize = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-package`,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY },
        }
      );

      if (Array.isArray(response.data)) {
        setPackageList(response.data);
      } else {
        setPackageList([]);
      }
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const checkPackage = async () => {
    try {
      const response = await getDataByUserId('PURCHASE_ORDER', localStorage.getItem('user_id'));

      if (response && response.length > 0) {
        response.forEach(item => {
          if (item.purchase_status === 'WP') {
            const selected = packageList.find(pkg => pkg.package_id === item.package_id);
            setPurchaseId(item.purchase_id);
            setSelectedPackageId(item.package_id);
            setSelectedPackage(selected);
            setShowPaymentPage(true);
          }

          if (item.purchase_status === 'WA') {
            setWaitingApprove(true);
          }
        });
      }
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const checkMember = async () => {
    try {
      const response = await getDataByUserId('USER_MEMBER', localStorage.getItem('user_id'));

      let filteredMembers = response.filter(member => member.member_status === 'N');
      let hiddenSubscribe = true;

      if (response.length === 0) {
        hiddenSubscribe = false;
      } else if (filteredMembers.length === 0) {
        const latestMemberWithE = response.reduce((latest, member) => {
          if (member.member_status === 'E' && (!latest || new Date(member.create_datetime) > new Date(latest.create_datetime))) {
            return member;
          }
          return latest;
        }, null);

        if (latestMemberWithE) {
          filteredMembers = [latestMemberWithE];
          hiddenSubscribe = false;
        }
      }

      setResultMember(filteredMembers);
      setHiddenSubscribe(hiddenSubscribe);
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const handleSubscribeClick = async (packageId) => {
    try {
      const userId = localStorage.getItem('user_id');
      const selectedPackage = packageList.find(pkg => pkg.package_id === packageId);

      const response = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/create-checkout-session`,
        {
          user_id: userId,
          package_id: packageId,
          amount: selectedPackage.price,
          currency: 'thb'
        },
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY },
        }
      );

      if (!response.data.url) {
        throw new Error('Invalid response from server');
      }

      window.location.href = response.data.url;
    } catch (error) {
      console.error('Subscribe error:', error);
      createAlert('FAILED', error.response?.data?.message || error.message);
    }
  };

  const sortedPurchases = purchaseHistory.sort((a, b) => new Date(a.purchase_date) - new Date(b.purchase_date));

  const handlePurchaseHistoryClick = async () => {
    try {
      const response = await getDataByUserId('PURCHASE_ORDER', localStorage.getItem('user_id'));
      if (response.length > 0) {
        setPurchaseHistory(response);
      } else {
        setPurchaseHistory([]);
      }
      setShowPurchaseHistoryModal(true);
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  }

  return (
    <div className="Package">
      <Header />
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mb-5">
          <div>
            <h4 className="display-6 fw-semibold" style={{ color: 'red' }}>Your Subscription</h4>
          </div>
          <div>
            <button className="btn btn-secondary" onClick={handlePurchaseHistoryClick}>Purchase History</button>
          </div>
        </div>
        {resultMember.length > 0 ? (
          <div className="text-left mb-5">
            <h4 className="fw-semibold" style={{ color: 'red' }}>
              Status: <span style={{ color: resultMember[0].member_status === 'N' ? 'green' : 'red' }}>
                {resultMember[0].member_status === 'N' ? 'Active' : 'Expired'}
              </span>
            </h4>
            <h5 className="fw-semibold" style={{ color: 'gray' }}>
              <i className="fa fa-calendar mt-3" aria-hidden="true"></i>
              <span style={{ color: 'gray', paddingLeft: '1rem' }}>Activate: {formatDate(resultMember[0].member_start)}</span>
            </h5>
            <h5 className="fw-semibold" style={{ color: 'gray' }}>
              <i className="fa fa-calendar mt-3" aria-hidden="true"></i>
              <span style={{ color: 'gray', paddingLeft: '1rem' }}>Expired: {formatDate(resultMember[0].member_end)}</span>
            </h5>
          </div>
        ) : (
          <div className="text-left mb-5">
            <h4 className="fw-semibold" style={{ color: 'red' }}>Status: <span style={{ color: 'black' }}>No Subscription</span></h4>
          </div>
        )}
        <hr className='mb-5' />
      </div>
      {showPaymentPage ? (
        <Payment purchaseId={purchaseId} packageId={selectedPackageId} />
      ) : (
        <div className="container">
          <div className="text-left mb-5">
            <h4 className="display-6 fw-semibold" style={{ color: 'red' }}>Subscription</h4>
          </div>

          {waitingApprove && (
            <div className="text-left mb-5">
              <h5 className="waiting-text">Waiting admin for checking your subscription</h5>
            </div>
          )}

          <div className="row mt-3">
            {Array.isArray(packageList) && packageList.length > 0 ? (
              packageList.map((item) => (
                <div key={item.package_id} className="col-sm-12 col-lg-4 col-xl-4 mb-5">
                  <div className="card" style={{ width: "20rem", textAlign: 'center' }}>
                    <div className="card-body mt-3">
                      <h5 className="card-title text-danger">{item.name}</h5>
                      <h2 className="card-text mt-3 text-danger">{item.price} Baht</h2>
                      {item.detail ? (
                        <p className="mt-3 text-white bg-danger">{item.detail}</p>
                      ) : (
                        <p className="mt-3 text-white">&nbsp;</p>
                      )}
                      <button
                        className="btn btn-info mt-3 text-white"
                        onClick={() => handleSubscribeClick(item.package_id)}
                        hidden={waitingApprove || hiddenSubscribe}>
                        <b>Subscribe</b>
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No packages available</p>
            )}
          </div>
        </div>
      )}
      <Footer isPadding={true} />
      {/* Purchase History Modal */}
      <div className={`modal ${showPurchaseHistoryModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showPurchaseHistoryModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-lg"> {/* Added modal-lg class for large width */}
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Purchase History</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowPurchaseHistoryModal(false)}></button>
            </div>
            <div className="modal-body">
              {purchaseHistory.length > 0 ? (
                <table className="table table-striped table-bordered table-hover">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Purchase Price</th>
                      <th scope="col">Purchase Date</th>
                      <th scope="col">Approval Date</th>
                      <th scope="col">Receipt Date</th>
                      <th scope="col">Purchase Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedPurchases.map((purchase, index) => (
                      <PurchaseRow key={index} index={index} purchase={purchase} />
                    ))}
                    {/* {purchaseHistory.map((purchase, index) => (
                      <PurchaseRow key={index} index={index} purchase={purchase} />
                    ))} */}
                  </tbody>
                </table>
              ) : (
                <p>No packages available</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* End Purchase History Modal */}
    </div>
  );
}

export default Package;
